import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const AboutPageTemplate = ({
  title,
  content,
  skills,
  contentComponent,
  image,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div style={{ color: "#fff" }}>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `top center`,
          backgroundAttachment: `scroll`,
          height: `800px`,
        }}
      >
        <div
          style={{
            display: "flex",
            height: "200px",
            lineHeight: "1",
            justifyContent: "space-around",
            alignItems: "left",
            flexDirection: "column",
            color: "#fff",
          }}
        >
          <div
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow: "#F8C9D8 0.9rem 10px 0px",
              backgroundColor: "#98C0D1",
              color: "#fff",
              lineHeight: "1",
              padding: "0.25em",
            }}
          >
            {title}
          </div>
        </div>
      </div>

      <div className="container" style={{ paddingTop: "10%" }}>
        <PostContent content={content} style={{ FontSize: "15" }} />
      </div>
      {skills && skills.map((skill) => <div>{skill}</div>)}
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string,
  // body: PropTypes.node.isRequired,
  skills: PropTypes.string,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <AboutPageTemplate
        title={frontmatter.title}
        body={post.html}
        skills={frontmatter.skills}
        contentComponent={HTMLContent}
        image={frontmatter.image}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      frontmatter {
        title
        skills
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
// {
//   childImageSharp {
//     fluid(maxWidth: 2048, quality: 100) {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }
